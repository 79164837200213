import { React, useEffect, useRef, useState, useCallback } from 'react';



import Navbar from './components/Navbar.jsx';
import Footer from './components/Footer.jsx';
import Home from './pages/Home.jsx';
import Post from './pages/Post.jsx';
import Login from './pages/Login.jsx';
import PrivacyPolicy from './pages/PrivacyPolicy.jsx';
import Contact from './pages/Contact.jsx';
import TheProject from './pages/TheProject.jsx';
import TermsOfService from './pages/TermsOfService.jsx';


import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CustomLink from './components/CustomLink'; // Adjust the import path as necessary


import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import { RenderingProvider } from './components/RenderingContext'; // Adjust the import path as necessary

import axios from 'axios';


//Templates
import './fonts.css'; // Import the CSS file
import './App.css';
import './css/templates.css'; // Load the default CSS by default
import './css/templatesHoverImages.css'; // Load the default CSS by default





// The polyfill for the Safari browser. The dynamic require is needed to work with SSR.
if (typeof window !== 'undefined') {
    require('context-filter-polyfill');
}




//console.log("Cookie Consent Value:", getCookieConsentValue("mySiteCookieConsent"));


function App() {

    const [thereAreVideos, setThereAreVideos] = useState(false);
    const [totalVideos, setTotalVideos] = useState(0);
    const [totalTimeSeconds, setTotalTimeSeconds] = useState(0);
    const [totalFileSizeMB, setTotalFileSizeMB] = useState(0);
    const [userTier, setUserTier] = useState(0);

    const [showCookieConsent, setShowCookieConsent] = useState(false);



    useEffect(() => {
        const consentValue = getCookieConsentValue("mySiteCookieConsent");
        //console.log("Initial Cookie Consent Value:", consentValue);
        if (!consentValue) {
            setShowCookieConsent(true);
        }
    }, []);

    const handleAcceptCookie = () => {
        setShowCookieConsent(false);
    };

    //GOOGLE LOGIN with PASSPORT
    // const user = false;
    const [user, setUser] = useState(null);




    useEffect(() => {
        const getUser = () => {
            fetch("https://www.AlbumAnimator.io/auth/login/success", {
                method: "GET",
                credentials: "include",
                //credentials: "true",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Credentials": true, //this doesn't seem to affect anything'
                    //"Access-Control-Allow-Origin": true,

                },            
            }).then(response => {
                if (response.status === 200) return response.json();
                throw new Error("authentication has been failed!")
            }).then(resObject => {
                setUser(resObject.user)
            }).catch(err => {
                console.log(err);
            });
          //  }, 1000);  // 1-second delay to ensure the session cookie is properly set
        };
        getUser();
    }, []);




 



    const addCredits = async () => {
        try {
            const response = await axios.post('https://www.albumanimator.io/credits/add-credits', {
                userId: user._id, // Assuming user._id is the user ID
                credits: 10 // Number of credits to add
            });
            setUser(response.data); // Update user state with new credits
        } catch (error) {
            console.error('Error adding credits:', error);
        }
    };

    const subtractCredits = async () => {
        try {
            const response = await axios.post('https://www.albumanimator.io/credits/subtract-credits', {
                userId: user._id, // Assuming user._id is the user ID
                credits: 5 // Number of credits to subtract
            });
            setUser(response.data); // Update user state with new credits
        } catch (error) {
            console.error('Error subtracting credits:', error);
        }
    };



    const updateUserUsage = async () => {
        //console.log(totalTimeSeconds);
        //console.log(Number(totalTimeSeconds));
        //console.log(Number(totalFileSizeMB));
        try {
            const response = await axios.post('https://www.albumanimator.io/credits/update-usage', {
                userId: user._id, // Assuming user._id is the user ID
                totalVideos: totalVideos,
                totalTimeSeconds: Number(totalTimeSeconds),
                totalFileSizeMB: Number(totalFileSizeMB)
            });
            setUser(response.data); // Update user state with new credits
            setTotalVideos(0);
            setTotalTimeSeconds(0);
            setTotalFileSizeMB(0);
        } catch (error) {
            console.error('Error updating user usage:', error);
        }
    };


    // useEffect to listen to changes in totalVideos and trigger updateUserUsage
    useEffect(() => {
        if (totalVideos > 0) {  // Optional: Only trigger when totalVideos is greater than 0
            updateUserUsage();
        }
    }, [totalVideos]);  // Dependency array with totalVideos, this will run updateUserUsage when totalVideos changes





    return (
        <RenderingProvider>
            <div className="App">

                {/*Navbar*/}
                <BrowserRouter>
                    <div>
                        <Navbar user={user}
                            thereAreVideos={thereAreVideos}
                            setThereAreVideos={setThereAreVideos} />
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/login"
                                element={user ? <Navigate to="/" /> : <Login />}
                            />
                            <Route path="/post/:id"
                                element={user ? <Post
                                    thereAreVideos={thereAreVideos}
                                    setThereAreVideos={setThereAreVideos}
                                    totalVideos={totalVideos}
                                    setTotalVideos={setTotalVideos}
                                    totalTimeSeconds={totalTimeSeconds}
                                    setTotalTimeSeconds={setTotalTimeSeconds}
                                    totalFileSizeMB={totalFileSizeMB}
                                    setTotalFileSizeMB={setTotalFileSizeMB}
                                    userTier={userTier}
                                    setUserTier={setUserTier}

                                /> : <Navigate to="/login" />}
                            />
                            <Route path="/Privacy" element={<PrivacyPolicy />} />
                            <Route path="/Contact" element={<Contact />} />
                            <Route path="/TheProject" element={<TheProject />} />
                            <Route path="/TermsOfService" element={<TermsOfService />} />

                        </Routes>

                        {/* Cookie Consent */}
                        {showCookieConsent && (
                            <CookieConsent
                                debug={true}
                                location="bottom"
                                style={{ background: 'rgba(0, 0, 0, 0.8)', textAlign: "left" }}
                                buttonStyle={{ color: "#000", background: "#fff", fontSize: "14px" }}
                                buttonText="I understand"
                                cookieName="mySiteCookieConsent"
                                expires={7}
                                onAccept={handleAcceptCookie}
                            >
                                This site uses cookies to enhance the user experience. See our <CustomLink to="/Privacy">privacy policy</CustomLink> for more.
                            </CookieConsent>
                        )}

                        <Footer
                            thereAreVideos={thereAreVideos}
                            setThereAreVideos={setThereAreVideos} />


                    </div>
                </BrowserRouter>


                {/*CREDITS*/}
                {/*{user && (*/}
                {/*    <div>*/}
                {/*        */}{/*<h1>User Credits: {user.credits}</h1>*/}
                {/*        <button onClick={addCredits}>Add Credits</button>*/}
                {/*        <button onClick={subtractCredits}>Subtract Credits</button>*/}
                {/*    </div>*/}
                {/*)}*/}

            </div>

        </RenderingProvider>
    );
};


export default App;


