import { React, useEffect, useRef, useState } from 'react';


//UI Components
import ColorPicker from './ColorPicker.jsx';
import FontsList from '../FontsList.jsx';
import MultiRangeSlider from "multi-range-slider-react";

import EditImagesButton from '../icons/EditImagesButton.jsx';
import EditAudioButton from '../icons/EditAudioButton.jsx';
import EditColorsButton from '../icons/EditColorsButton.jsx';
import EditVisualizerButton from '../icons/EditVisualizerButton.jsx';
import EditExportButton from '../icons/EditExportButton.jsx';
import VideosLibrary from '../icons/VideosLibrary.jsx';
import DeleteTrack from '../icons/DeleteTrack.jsx';


import Black_img from '../assets/images/Black_img.jpg';

import { CopyToClipboard } from 'react-copy-to-clipboard'; // If using react-copy-to-clipboard
import CopyToClipboardIcon from "../img/CopyToClipboardIcon.png";

import DownloadIcon from "../icons/DownloadIcon";

import "./Navigation.css";


//Image Files
import Background_default from '../assets/images/Background_default.webp';
import Cover_default from '../assets/images/Cover_default.webp';
import Logo_default from '../assets/images/Logo_default.png';
import UploadAudio from "../img/UploadAudio.png";
import DownloadZip from "../img/DownloadZip.png";



//Cropper Library
import { Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css';
import cn from 'classnames';
import AdjustableCropperBackground from './AdjustableCropperBackground.jsx';
//import AdjustablePreviewBackground from './AdjustablePreviewBackground.jsx';
import Button from './Button.jsx';
import Navigation from './Navigation.jsx';
import Slider from './Slider.jsx';
import ResetIcon from '../icons/ResetIcon.jsx';
import '../css/ImageEditor.css';
import '../css/buttons.css';


//Visualizer
import AudioVisualizer34e from './AudioVisualizer34e.jsx';
import AudioVisualizer37fFREE from './AudioVisualizer37fFREE.jsx';

//FFMPEG
import { FFmpegProvider } from './FFmpegContext.jsx';

//Download
import { saveAs } from 'file-saver';
import JSZip from "jszip";


//Templates
import '../fonts.css'; // Import the CSS file
import '../App.css';
import '../css/templates.css'; // Load the default CSS by default
import '../css/templatesHoverImages.css'; // Load the default CSS by default
import '../css/constants.css'; // Load the default CSS by default



import { useRendering } from './RenderingContext'; // Adjust the import path as necessary



const MainFunction_Tier_0 = ({ postId, thereAreVideos, setThereAreVideos,
    totalVideos, setTotalVideos, totalTimeSeconds, setTotalTimeSeconds, totalFileSizeMB, setTotalFileSizeMB }) => {
    const { isRendering, setIsRendering } = useRendering();


    const [latestFrameUrl, setLatestFrameUrl] = useState(Black_img);



    // START - Image Selection /////////////
    const [selectedImage, setSelectedImage] = useState('Backgound');
    const handleImgSelection = (selectedImage) => {
        setSelectedImage(selectedImage);
        onReset();
        setMode('crop');
        const imgSources = {
            Background: imgSourceBackground,
            Cover: imgSourceCover,
            Logo: imgSourceLogo,
        };
        const selectedImageSrc = imgSources[selectedImage];
        setSrc(selectedImageSrc);
    };

    const [imgSourceBackground, setImgSourceBackground] = useState(Background_default);
    const [imgSourceCover, setImgSourceCover] = useState(Cover_default);
    const [imgSourceLogo, setImgSourceLogo] = useState(Logo_default);

    const [src, setSrc] = useState(imgSourceBackground); //this in what is loaded on the Cropper

    const imageFunctions = {
        Background: setImgSourceBackground,
        Cover: setImgSourceCover,
        Logo: setImgSourceLogo,
    };
    const selectedFunction = imageFunctions[selectedImage];
    // END - Image Selection /////////////



    //ASPECT RATIO Start
    let aspectRatio;
    switch (selectedImage) {
        case 'Background':
            aspectRatio = 16 / 9;
            break;
        case 'Cover':
            aspectRatio = 1; // Example aspect ratio for 'Cover'
            break;
        case 'Logo':
            aspectRatio = 1; // Example aspect ratio for 'Logo'
            break;
        default:
            aspectRatio = 16 / 9; // Default aspect ratio
    }
    //ASPECT RATIO End





    // START - Videos /////////////
    const [videos, setVideos] = useState([]);
    const [videoTitles, setVideoTitles] = useState([]);
    const [outputVideo, setOutputVideo] = useState('');
    const [concatenatedVideoUrl, setConcatenatedVideoUrl] = useState(null);
    const [concatenatedVideoTitle, setConcatenatedVideoTitle] = useState('My Album');





    // END - Videos /////////////




    // START - AUDIO Files /////////////
    let defaultAudioFile;

    if (postId === 3) {
        defaultAudioFile = [
            {
                name: "Artist 1 - Default Title 1",
                duration: 7.471, // You may need to load this dynamically or enter it manually
                source: "/samplemusic.mp3",
                sorcer: '/samplemusic.mp3', // Direct URL to the audio file
                artist: "Artist 1",
                title: "Title 1",
                trackNumber: 1,
            },
            {
                name: "Default Artist 2 - Default Title 2",
                duration: 7.471, // You may need to load this dynamically or enter it manually
                source: "/samplemusic.mp3",
                sorcer: '/samplemusic.mp3', // Direct URL to the audio file
                artist: "Default Artist 2",
                title: "Default Title 2",
                trackNumber: 2,
            },
            {
                name: "Artist 3 - Title 3",
                duration: 7.471, // You may need to load this dynamically or enter it manually
                source: "/samplemusic.mp3",
                sorcer: '/samplemusic.mp3', // Direct URL to the audio file
                artist: "Artist 3",
                title: "Title 3",
                trackNumber: 3,
            },
        ];
    }

    else  {
        defaultAudioFile = [
            {
                name: "Default Title",
                duration: 7.471, // You may need to load this dynamically or enter it manually
                source: "/samplemusic.mp3",
                sorcer: '/samplemusic.mp3', // Direct URL to the audio file
                artist: "Default Artist",
                title: "Default Title",
                trackNumber: 1,
            },           
        ];
    }

   
    const [audioFiles, setAudioFiles] = useState(defaultAudioFile);
    const [selectedAudioIndex, setSelectedAudioIndex] = useState(0);





    const [albumTracksNames, setAlbumTracksNames] = useState([]);
    const [longestName, setLongestName] = useState("");

    useEffect(() => {
        // Update albumTracksNames
        const names = audioFiles.map(item => item.name);
        setAlbumTracksNames(names);

        // Find the longest name
        const longest = names.reduce((longest, current) => {
            return current.length > longest.length ? current : longest;
        }, "");
        setLongestName(longest);

       // console.log("Longest Name: ", longest);
       // console.log("albumTracksNames: ", names);

    }, [audioFiles]); // This effect runs whenever audioFiles changes




    const parseAudioTitle = (fileName) => {
        const songTitle = fileName.slice(0, -4); // Remove ".mp3" or any file extension

        // Regular expression to detect valid track numbers at the beginning of the title (1-3 digits)
        const trackNumberPattern = /^(\d{1,3})(\s*[.-]?\s*)?(.+)/; // Allow optional separator or no space
        const match = songTitle.match(trackNumberPattern);

        let cleanTitle = '';
        let trackNumber = '';

        if (match) {
            const possibleTrackNumber = match[1]; // Capture the digits at the start
            const remainingTitle = match[3].trim(); // Capture the rest of the title

            // Additional validation: Check if the number makes sense as a track number
            if (Number(possibleTrackNumber) > 0 && Number(possibleTrackNumber) <= 999) {
                trackNumber = possibleTrackNumber;
                cleanTitle = remainingTitle;
            } else {
                cleanTitle = songTitle; // If it's not a valid track number, treat the whole title as clean
            }
        } else {
            cleanTitle = songTitle; // If no match, the whole title is clean
        }

        return {
            trackNumber: trackNumber || '', // Return null if no valid track number is found
            cleanTitle: cleanTitle,
        };
    };





    const handleInputSound = async (event) => {
        setNumberCheck(false);

        const files = Array.from(event.target.files); // Convert FileList to an array
        const updatedAudioFiles = [];

        if (files.length < 20) {
            // Sort files alphabetically and numerically by name
            files.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                // Extract numerical part if exists
                const numA = nameA.match(/\d+/);
                const numB = nameB.match(/\d+/);

                if (numA && numB) {
                    const diff = parseInt(numA[0]) - parseInt(numB[0]);
                    if (diff !== 0) {
                        return diff;
                    }
                }

                return nameA.localeCompare(nameB);
            });

            for (let i = 0; i < files.length; i++) { // Changed loop direction to forward
                const file = files[i];
                const audioElement = document.createElement('audio');

                const audioSrc = URL.createObjectURL(file);
                audioElement.src = audioSrc;

                const songTitle = file.name.slice(0, -4); // remove ".mp3"

                // Remove NUMBER and dot and dash and spaces before artist-title
                //const trackNumberPattern = /^(\d+)\s*[.-]\s*(.+)/;
                //const trackNumberPattern = /^(\d{1,3})(\s*[.-]?\s*)?(.+)/; // Allow optional separator or no space
                // const match = songTitle.match(trackNumberPattern);
                // let cleanTitle = [];
                // let trackNumber = [];
                let finaltitle = [];

                let { trackNumber, cleanTitle } = parseAudioTitle(songTitle);

                // console.log('Track Number:', trackNumber);  // e.g., "10"
                // console.log('Clean Title:', cleanTitle);    // e.g., "samplemusic - jj"

                //if (match) {
                //    trackNumber = match[1]; // Use the first capturing group for the track number
                //    cleanTitle = match[2].trim(); // Use the second capturing group for the clean title
                //} else {
                //    cleanTitle = songTitle;
                //}


                finaltitle = songTitle;

                let [artist, title] = [];

                const separator = ' - ';
                const parts = finaltitle.split(separator);

                // Check if there's at least one separator
                if (parts.length < 2) {
                    [artist, title] = [finaltitle, '']; // No separator found
                } else {
                    // Handle the case where the first element might be part of the artist's name
                    if (/^\d+\.\s*/.test(parts[0])) {
                        // If the first part has a number followed by a dot, treat it as part of the artist's name
                        artist = parts[0];
                        title = parts.slice(1).join(separator); // Join all parts except the first one with the separator to get the title
                    } else {
                        // Otherwise, join all parts except the last one with the separator to get the artist
                        artist = parts.slice(0, -1).join(separator);
                        title = parts[parts.length - 1];
                    }
                }


                await new Promise((resolve) => {
                    audioElement.addEventListener('loadedmetadata', () => {
                        const audioDuration = audioElement.duration;
                        const audioFile = {
                            name: file.name.slice(0, -4),  // remove ".mp3"
                            duration: audioDuration,
                            source: file,
                            sorcer: URL.createObjectURL(file),
                            artist: artist,
                            title: title,
                            trackNumber: trackNumber,
                        };

                        updatedAudioFiles.push(audioFile);
                        resolve();
                    });
                });

                URL.revokeObjectURL(audioElement.src);
            }
            if (event.target.files.length > 0) {
                setAudioFiles(updatedAudioFiles);
                setSelectedAudioIndex(0); // Reset the selected audio index to 0
            }
        } else {
            alert('You can upload a maximum of 20 audio files.');
        }
    };







    const [albumCheck, setAlbumCheck] = useState(false); //if initialized with false, "1 - title" can't break the number
    const handleAlbumCheckChange = (event) => {
        setAlbumCheck(event.target.checked);
    }
    
    const [hideBackgroundMask, setHideBackgroundMask] = useState(false); //if initialized with false, "1 - title" can't break the number
    const handleHideBackgroundMaskChange = (event) => {
        setHideBackgroundMask(event.target.checked);
    }
    const [hideCoverMask, setHideCoverMask] = useState(false); //if initialized with false, "1 - title" can't break the number
    const handleHideCoverMaskChange = (event) => {
        setHideCoverMask(event.target.checked);
    }

    const [numberCheck, setNumberCheck] = useState(false); //if initialized with false, "1 - title" can't break the number
    const handleNumberCheckChange = (event) => {
        setNumberCheck(event.target.checked);

        const updatedFiles = [...audioFiles]; // Copy the audioFiles array

        for (let index = (audioFiles.length - 1); index >= 0; index--) {
            const file = audioFiles[index];

            const songTitle = file.name;
            const artist = file.artist;

            // Updated regex pattern to account for cases where the track number is followed by a space, dot, or dash
            const trackNumberPattern = /^(\d+)\s*[.-]?\s*(.+)/;
            const match = songTitle.match(trackNumberPattern);
            const match2 = artist.match(trackNumberPattern);

            let cleanTitle = [];
            let cleanArtist = [];
            let trackNumber = file.trackNumber;
            let finaltitle = [];
            let finalArtist = [];

            if (match && match2) {
                trackNumber = match[1]; // Use the first capturing group for the track number
                cleanTitle = match[2].trim(); // Use the second capturing group for the clean title
                cleanArtist = match2[2].trim(); // Use the second capturing group for the clean artist name
            } else {
                console.log("Track number not found");
                cleanTitle = songTitle;
                cleanArtist = artist;
            }

            if (numberCheck === false) {
                finaltitle = cleanTitle;
                finalArtist = cleanArtist;
            } else if (numberCheck === true) {
                if (trackNumber === '') {
                    finaltitle = `${songTitle}`;
                    finalArtist = `${artist}`;
                } else {
                    finaltitle = `${trackNumber}. ${cleanTitle}`;
                    finalArtist = `${trackNumber}. ${cleanArtist}`;
                }
            }

            updatedFiles[index].name = `${finaltitle}`;
            updatedFiles[index].artist = `${finalArtist}`;

            setAudioFiles(updatedFiles);
        }
    };



    const handleAudioArtistChange = (event, index) => {
        const updatedFiles = [...audioFiles];
        updatedFiles[index].artist = event.target.value;
        if (updatedFiles[index].title === '') {
            updatedFiles[index].name = `${updatedFiles[index].artist}`;
        }
        else {
            updatedFiles[index].name = `${updatedFiles[index].artist} - ${updatedFiles[index].title}`;
        }
        setAudioFiles(updatedFiles);
       // console.log("updatedFiles:", updatedFiles[index].name);

    };
    const handleAudioTitleChange = (event, index) => {
        const updatedFiles = [...audioFiles];
        updatedFiles[index].title = event.target.value;
        if (updatedFiles[index].title === '') {
            updatedFiles[index].name = `${updatedFiles[index].artist}`;
        }
        else {
            updatedFiles[index].name = `${updatedFiles[index].artist} - ${updatedFiles[index].title}`;
        }
        setAudioFiles(updatedFiles);
        console.log("updatedFiles:", updatedFiles[index].name);

    };

    const audioInputRef = useRef(null);
    const handleAudioButtonClick = () => {

        const userConfirmed = window.confirm('If you proceed, the current playlist will be deleted. Do you want to continue?');

        if (userConfirmed) {
            // Proceed with uploading audio or any other actions
            // console.log('User confirmed. Proceed with action.');
            if (audioInputRef.current) {
                audioInputRef.current.click(); // Programmatically trigger the file input dialog
            }
            // Add your logic to handle the upload action here.
        } else {
            // Cancel the action
        }
    };



    const newTrackInputRef = useRef(null);


    const handleNewTrackButtonClick = () => {
        if (newTrackInputRef.current) {
            newTrackInputRef.current.click(); // Trigger the file input for uploading new track(s)
        }
    };


    const handleNewTrackInput = async (event) => {
        setNumberCheck(false);

        const newFiles = Array.from(event.target.files); // Convert FileList to an array
        const updatedAudioFiles = [...audioFiles]; // Copy existing audio files to update

        if (newFiles.length + audioFiles.length <= 20) {
            // Sort new files alphabetically and numerically by name
            newFiles.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();

                // Extract numerical part if exists
                const numA = nameA.match(/\d+/);
                const numB = nameB.match(/\d+/);

                if (numA && numB) {
                    const diff = parseInt(numA[0]) - parseInt(numB[0]);
                    if (diff !== 0) {
                        return diff;
                    }
                }

                return nameA.localeCompare(nameB);
            });

            for (let i = 0; i < newFiles.length; i++) {
                const file = newFiles[i];
                const audioElement = document.createElement('audio');

                const audioSrc = URL.createObjectURL(file);
                audioElement.src = audioSrc;

                const songTitle = file.name.slice(0, -4); // Remove ".mp3"

                // Remove NUMBER and dot/dash/spaces before artist-title
                //const trackNumberPattern = /^(\d+)\s*[.-]\s*(.+)/;
                // const match = songTitle.match(trackNumberPattern);
                // let cleanTitle = [];
                // let trackNumber = [];
                let finaltitle = [];

                let { trackNumber, cleanTitle } = parseAudioTitle(songTitle);


                //if (match) {
                //    trackNumber = match[1]; // Use the first capturing group for the track number
                //    cleanTitle = match[2].trim(); // Use the second capturing group for the clean title
                //} else {
                //    cleanTitle = songTitle;
                //}

                finaltitle = songTitle;

                let [artist, title] = [];

                const separator = ' - ';
                const parts = finaltitle.split(separator);

                // Check if there's at least one separator
                if (parts.length < 2) {
                    [artist, title] = [finaltitle, '']; // No separator found
                } else {
                    // Handle the case where the first element might be part of the artist's name
                    if (/^\d+\.\s*/.test(parts[0])) {
                        // If the first part has a number followed by a dot, treat it as part of the artist's name
                        artist = parts[0];
                        title = parts.slice(1).join(separator); // Join all parts except the first one to get the title
                    } else {
                        artist = parts.slice(0, -1).join(separator);
                        title = parts[parts.length - 1];
                    }
                }




                await new Promise((resolve) => {
                    audioElement.addEventListener('loadedmetadata', () => {
                        const audioDuration = audioElement.duration;
                        const audioFile = {
                            name: file.name.slice(0, -4),  // Remove ".mp3"
                            duration: audioDuration,
                            source: file,
                            sorcer: URL.createObjectURL(file),
                            artist: artist,
                            title: title,
                            trackNumber: trackNumber,
                        };

                        updatedAudioFiles.push(audioFile); // Add new track to the list
                        resolve();
                    });
                });

                URL.revokeObjectURL(audioElement.src);
            }

            if (newFiles.length > 0) {
                setAudioFiles(updatedAudioFiles); // Update the state with the new track(s)
            }
        } else {
            alert('You can upload a maximum of 20 audio files in total.');
        }
    };




    const handleDeleteTrack = (indexToDelete) => {

        if (audioFiles.length === 1) {
            alert('You must have at least 1 track in the tracklist.');
            return; // Prevent deletion
        }

        const userConfirmed = window.confirm('Are you sure you want to delete this track?');

        if (userConfirmed) {
            const updatedAudioFiles = audioFiles.filter((_, index) => index !== indexToDelete);

            // Update the audioFiles state
            setAudioFiles(updatedAudioFiles);

            // Adjust the selectedAudioIndex if needed
            if (selectedAudioIndex === indexToDelete) {
                // Reset to the first track if the deleted track was the selected one
                setSelectedAudioIndex(0);
            } else if (selectedAudioIndex > indexToDelete) {
                // Decrease the selected index if the deleted track was before the current selection
                setSelectedAudioIndex(selectedAudioIndex - 1);
            }
        } else {
            // If the user cancels, do nothing
            // console.log('User canceled the delete action');
        }
    };





    const handleMoveUp = (index) => {
        if (index === 0) return; // Already at the top, no need to move

        const updatedAudioFiles = [...audioFiles];
        // Swap the current track with the one above it
        [updatedAudioFiles[index], updatedAudioFiles[index - 1]] = [updatedAudioFiles[index - 1], updatedAudioFiles[index]];

        setAudioFiles(updatedAudioFiles);
    };

    const handleMoveDown = (index) => {
        if (index === audioFiles.length - 1) return; // Already at the bottom, no need to move

        const updatedAudioFiles = [...audioFiles];
        // Swap the current track with the one below it
        [updatedAudioFiles[index], updatedAudioFiles[index + 1]] = [updatedAudioFiles[index + 1], updatedAudioFiles[index]];

        setAudioFiles(updatedAudioFiles);
    };





    // END - AUDIO Files /////////////


    //ATTENTION TO THE COMMA IN THE BEGINNING 1!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!







    // START - Text /////////////
    const [colorValues, setColorValues] = useState({
        color1: { color: '#FFFFFF', opacity: 0.8, name: 'Text' },
        color2: { color: '#00FFFF', opacity: 20, name: 'Glow' },
        color3: { color: '#FFD700', opacity: 20, name: 'Playing' },
        color4: { color: '#505153', opacity: 1, name: 'Song Shade' },
        color5: { color: '#b3b3b3', opacity: 0.1, name: 'Text Box' },
    });
    const handleColorChange = (id, color, opacity, name) => {
        setColorValues((prevColorValues) => ({
            ...prevColorValues,
            [id]: { color, opacity, name }, // Update the selected color and opacity in the main app state based on the ID
        }));
    };
    const [selectedFont, setSelectedFont] = useState('ACBlur');
    const handleFontChange = (event) => {
        setSelectedFont(event.target.value);
    };
    // END - Text /////////////






    // START - Image EDITOR /////////////
    const cropperRef = useRef(null);
    const previewRef = useRef(null);
    const [mode, setMode] = useState('crop');
    const [adjustments, setAdjustments] = useState({
        brightness: 0,
        hue: 0,
        saturation: 0,
        contrast: 0,
        blur: 0,
    });
    const onChangeValue = (value) => {
        if (mode in adjustments) {
            setAdjustments((previousValue) => ({
                ...previousValue,
                [mode]: value,
            }));
        }
    };
    const onReset = () => {
        setMode('crop');
        setAdjustments({
            brightness: 0,
            hue: 0,
            saturation: 0,
            contrast: 0,
            blur: 0,
        });
    };
    const onUpload = (blob) => {
        onReset();
        setMode('crop');
        setSrc(blob);
    };
    const onDownload = () => {
        if (cropperRef.current) {
            const croppedImageDataUrl = cropperRef.current.getCanvas()?.toDataURL();

            if (selectedFunction && croppedImageDataUrl) {
                const canvas = document.createElement('canvas');
                const context = canvas.getContext('2d');


                // Set canvas dimensions based on selectedImage
                switch (selectedImage) {
                    case 'Background':
                        canvas.width = 1280;
                        canvas.height = 720;
                        break;
                    case 'Cover':
                        canvas.width = 600;
                        canvas.height = 600;
                        break;
                    case 'Logo':
                        canvas.width = 150;
                        canvas.height = 150;
                        break;
                    // Add more cases if needed
                    default:
                        canvas.width = 1280;
                        canvas.height = 720;
                        break;
                }


                // Create an Image object directly without using new Image()
                const image = document.createElement('img');

                // Set the onload event to handle resizing once the image is loaded
                image.onload = () => {
                    // Draw the resized image onto the canvas
                    context.drawImage(image, 0, 0, canvas.width, canvas.height);

                    // Get the data URL of the resized image
                    const resizedImageDataUrl = canvas.toDataURL();

                    // Set the image source using the appropriate function
                    selectedFunction(resizedImageDataUrl);


                    /*
                    // Optionally, you can open the resized image in a new tab
                    const newTab = window.open();
                    if (newTab) {
                        newTab.document.body.innerHTML = `<img src="${resizedImageDataUrl}"/>`;
                    }
                    */
                };

                // Set the source of the Image element to the cropped image data URL
                image.src = croppedImageDataUrl;
            }
        }
    };
    const onUpdate = () => {
        previewRef.current?.refresh();
    };
    const changed = Object.values(adjustments).some((el) => Math.floor(el * 100));
    const cropperEnabled = mode === 'crop';
    // END - Image EDITOR /////////////








    /////////////// START - Window Buttons /////////////
    const [activeWindowButton, setActiveWindowButton] = useState('AudioFiles');
    const handleWindowButtonClick = (windowButtonName) => {
        if (isRendering) {
            alert('Rendering is in progress. Please wait until it is complete.');
            return;
        }
        setActiveWindowButton(windowButtonName);

        if (windowButtonName === 'Background' || windowButtonName === 'Cover' || windowButtonName === 'Logo') {
            if (isRendering) {
                alert('Rendering is in progress. Please wait until it is complete.');
                return;
            }
            handleImgSelection(windowButtonName)
        }


    };

    const getButtonClassName = (buttonName) => {
        return `inox-button ${activeWindowButton === buttonName ? 'inox-button-active' : ''}`;
    };
    /////////////// END - Window Buttons /////////////


    /////////////// START - Visualizer Hue /////////////
    const [minHueValue, setMinHueValue] = useState(60);
    const [maxHueValue, setMaxHueValue] = useState(120);
    const handleInputHue = (e) => {
        setMinHueValue(e.minValue);
        setMaxHueValue(e.maxValue);
    };
    function GradientBox({ minHueValue, maxHueValue }) {
        const boxStyle = {
            width: '60%',
            height: '10px',
            background: `linear-gradient(to right, hsl(${minHueValue}, 100%, 50%), hsl(${maxHueValue}, 100%, 50%))`,
        };

        return <div style={boxStyle}></div>;
    }
    /////////////// END - Visualizer Hue /////////////









    function handleDownload(videoUrl, fileName) {
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = fileName;  // Set the download attribute to the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }







    const handleConcatenatedVideoTitleChange = (event) => {       
        setConcatenatedVideoTitle(event.target.value);
    };


    const handleConcatenatedVideoDownload = () => {
        const link = document.createElement('a');
        link.href = concatenatedVideoUrl;
        link.download = `${ concatenatedVideoTitle +' - Video Album' || 'Video Album'}.mp4`; // Default to 'Album.mp4' if no filename is provided
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    };



    const formatDuration = (seconds) => {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
        const s = Math.floor(seconds % 60).toString().padStart(2, '0');

        if (h > 0) {
            return `${h}:${m}:${s}`;
        } else {
            return `${m}:${s}`;
        }
    };




    //Calculate start point of each track
    // Extract durations and calculate cumulative durations
    const durations = videos.map(video => video.duration);
    const cumulativeDurations = durations.reduce((acc, cur) => {
        const previousDuration = acc.length > 0 ? acc[acc.length - 1] : 0;
        acc.push(previousDuration + cur);
        return acc;
    }, [0]);




    const handleConcatenatedVideo = (url) => {
        setConcatenatedVideoUrl(url);
    };





    const [playingIndex, setPlayingIndex] = useState(null);
    const playerRefs = useRef([]);

    const handlePlay = (index) => {
        setPlayingIndex(index);
        // Pause all video players except the one currently playing
        playerRefs.current.forEach((player, i) => {
            if (i !== index && player) {
                if (player instanceof HTMLVideoElement) {
                    player.pause();
                } else {
                    player.getInternalPlayer().pause();
                }
            }
        });
    };

    const handlePause = () => {
        setPlayingIndex(null);
    };





    

    const generateCopyText = () => {
        const videoListText = videos.map((video, index) => {
            const title = video.title.endsWith('.mp4') ? video.title.slice(0, -4) : video.title;
            //return `${formatDuration(cumulativeDurations[index])} - ${title}`;
            return `${formatDuration(cumulativeDurations[index])} ${title}`;
        }).join('\n');

        return `${concatenatedVideoTitle}\n\n${videoListText}`;
    };





    const zipAndDownload = async () => {
        const zip = new JSZip();
        const folder = zip.folder(concatenatedVideoTitle); // Use the album title as the folder name

        // Add videos to the zip
        for (let i = 0; i < videos.length; i++) {
            const video = videos[i];
            const title = videoTitles[i].endsWith('.mp4') ? videoTitles[i].slice(0, -4) : video.title;
            const response = await fetch(video.url);
            const blob = await response.blob();
            folder.file(`${title}.mp4`, blob);
        }

        // Add concatenated video to the zip if it exists
        if (concatenatedVideoUrl) {
            const response = await fetch(concatenatedVideoUrl);
            const blob = await response.blob();
            folder.file(`${concatenatedVideoTitle+` - Video Album`}.mp4`, blob);
        }

        // Add the .txt file to the zip
        const textContent = generateCopyText(); // Assuming this generates the required text content
        folder.file(`${concatenatedVideoTitle+' - Description'}.txt`, textContent);

        // Generate the zip and trigger download
        zip.generateAsync({ type: 'blob' }).then((content) => {
            saveAs(content, `${concatenatedVideoTitle}.zip`);
        });
    };




    const [copied, setCopied] = useState(false);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Hide the popup after 2 seconds
    };





    const [tracksAlignment, setTracksAlignment] = useState('center'); //if initialized with false, "1 - title" can't break the number


    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
  



    return (



        <div>

           


            {/*<h2>Post ID: {postId}</h2>*/}

            {/*Golden Ratio Columns*/}
            {/*<div className="columns-container" >*/}
            <div className="main-application-container" >

               



                {/*Golden Nav Column*/}
                <div className="nav-column" >

                    <label className="editButton-wrapper">
                        <EditAudioButton onClick={() => handleWindowButtonClick('AudioFiles')} label="Audio"
                            isActive={activeWindowButton === 'AudioFiles'} />
                        <span className="editButtonTooltip">Upload Audio</span>
                    </label>

                    <label className="editButton-wrapper">
                        <EditImagesButton onClick={() => handleWindowButtonClick('Cover')} label="Cover"
                            isActive={activeWindowButton === 'Cover' || activeWindowButton === 'Background'} />
                        <span className="editButtonTooltip">Upload & Edit Images</span>
                    </label>

                    <label className="editButton-wrapper">
                        <EditColorsButton onClick={() => handleWindowButtonClick('Text')} label="Colors"
                            isActive={activeWindowButton === 'Text'} />
                        <span className="editButtonTooltip">Edit Fonts & Colors</span>
                    </label>

                    <label className="editButton-wrapper">
                        <EditVisualizerButton onClick={() => handleWindowButtonClick('Visualizer')} label="Visualizer"
                            isActive={activeWindowButton === 'Visualizer'} />
                        <span className="editButtonTooltip">Edit Visual Effects</span>
                    </label>

                    <label className="editButton-wrapper">
                        <EditExportButton onClick={() => handleWindowButtonClick('RenderVideo')} label="Export"
                            isActive={(activeWindowButton === 'RenderVideo' || activeWindowButton === 'Album Text')} />
                        <span className="editButtonTooltip">Export to Video</span>
                    </label>

                    <label className="editButton-wrapper">
                        <VideosLibrary onClick={() => handleWindowButtonClick('VideosLibrary')} label="VideosLibrary"
                            isActive={activeWindowButton === 'VideosLibrary'} />
                        <span className="editButtonTooltip">My Videos</span>
                    </label>

                </div>



                {/*Golden Big*/}
                <div className="columnGoldenBig" >

                    {/*Home*/}
                    {activeWindowButton === 'Home' && (
                        <div className={'image-editor'} style={{
                            height: `${window.innerHeight * 0.9}px`,
                        }}>
                            <div className="image-editor__cropper" style={{
                                textAlign: 'center'
                            }} >
                                <h2 style={{ margin: 0, padding: 0 }}>Welcome to Home.</h2>
                                <p>Click on an image to your left to modify.</p>
                            </div>
                        </div>
                    )}


                    {/*Cropper*/}
                    {(activeWindowButton === 'Background' || activeWindowButton === 'Cover' || activeWindowButton === 'Logo' || activeWindowButton === 'Images') && (

                        <div className={'image-editor'}>

                            <h2 style={{ margin: 0, padding: 0 }}>Edit Images</h2>
                            <br></br>

                            {/*<br></br>*/}
                            <div className="blueLine"></div>


                            <div className="button-container-ChooseImage">
                                <button                                    
                                    className={getButtonClassName('Cover')} 
                                    onClick={() => handleWindowButtonClick('Cover')}
                                >
                                    Edit Cover
                                </button>

                                <button
                                    className={getButtonClassName('Background')}
                                    onClick={() => handleWindowButtonClick('Background')}
                                >
                                    Edit Background
                                </button>
                            </div>


                            <div className="image-editor__cropper">

                                <Cropper
                                    className={'image-editor__fixedSize'}
                                    src={src}
                                    ref={cropperRef}
                                    aspectRatio={aspectRatio}
                                    stencilProps={{
                                        movable: cropperEnabled,
                                        resizable: cropperEnabled,
                                        lines: cropperEnabled,
                                        handlers: cropperEnabled,
                                        grid: cropperEnabled,
                                        overlayClassName: cn(
                                            'image-editor__cropper-overlay',
                                            !cropperEnabled && 'image-editor__cropper-overlay--faded',
                                        ),
                                    }}
                                    backgroundWrapperProps={{
                                        scaleImage: cropperEnabled,
                                        moveImage: cropperEnabled,
                                    }}
                                    backgroundComponent={AdjustableCropperBackground}
                                    backgroundProps={adjustments}
                                    onUpdate={onUpdate}
                                />



                                <div className="image-editor-leftright">
                                    <div className="image-editor-navigation__left">
                                        <b><i>Upload & Edit </i></b>
                                        <span className="simpleText">your Images</span>
                                    </div>
                                    <div className="image-editor-navigation__right">
                                        <span>Now editing: </span>
                                        <span className="simpleText">{selectedImage}</span>
                                        <span className="simpleText"> | {capitalizeFirstLetter(mode)}</span>
                                    </div>
                                </div>

                                <br></br>

                                {mode !== 'crop' && (
                                    <Slider className="image-editor__slider"
                                        value={adjustments[mode]}
                                        onChange={onChangeValue} />
                                )}


                                <Button
                                    className={cn('image-editor__reset-button',
                                        !changed && 'image-editor__reset-button--hidden'
                                    )}
                                    onClick={onReset}
                                >
                                    <ResetIcon />
                                </Button>
                            </div>



                            <div>
                                <Navigation
                                    mode={mode}
                                    onChange={setMode}
                                    onUpload={onUpload}
                                    onDownload={onDownload}
                                />
                            </div>

                        </div>



                    )}


                    {/*Visualizer*/}
                    {activeWindowButton === 'Visualizer' && (




                        <div>
                            <div>

                                <h2 style={{ margin: 0, padding: 0 }}>Visualizer Editor</h2>

                                <br></br>
                                <div className="blueLine"></div>



                                <div>
                                    <div className="containerUploadAudio">
                                        <span className="textUploadAudio">
                                            <span style={{ fontSize: '2em' }}>1. </span>
                                            <i><b>Visualizer </b></i>
                                            <span className="simpleText">color range</span>

                                            <center>
                                                <div style={{ margin: 0, paddingTop: '10px' }}>

                                                    <MultiRangeSlider
                                                        min={0}
                                                        max={360}
                                                        step={5}
                                                        ruler={false}
                                                        label={false}
                                                        preventWheel={false}
                                                        minValue={minHueValue}
                                                        maxValue={maxHueValue}
                                                        onInput={(e) => {
                                                            handleInputHue(e);
                                                        }}
                                                        style={{
                                                            width: '90%',
                                                            background: `linear-gradient(to right,
                                              hsl(0, 100%, 50%), 
                                              hsl(60, 100%, 50%), 
                                              hsl(120, 100%, 50%), 
                                              hsl(180, 100%, 50%), 
                                              hsl(240, 100%, 50%), 
                                              hsl(300, 100%, 50%), 
                                              hsl(360, 100%, 50%))`,
                                                        }} // Example background color
                                                    />
                                                    <div>
                                                        <br></br>
                                                        <GradientBox minHueValue={minHueValue} maxHueValue={maxHueValue} />

                                                    </div>
                                                </div>
                                            </center>
                                        </span>
                                    </div>
                                </div>

                                <div className="blueLine"></div>

                                <br></br>

                                <center>
                                    <p className="simpleText"><i>Visualizers and Particles are Coming Soon..</i></p>
                                    <p className="simpleText"><i>Stay Tuned!</i></p>
                                </center>
                            </div>
                        </div>
                    )}


                    {/*Text*/}
                    {activeWindowButton === 'Text' && (
                        <div>
                            <div>

                                <h2 style={{ margin: 0, padding: 0 }}>Colors & Text</h2>

                                <br></br>


                                <div className="blueLine"></div>




                                 <div >

                                    <li className="columns-container" >
                                        <div className="textUploadAudio">

                                            <span style={{ fontSize: '2em' }}>1. </span>
                                            <i><b>Text Font </b></i>
                                            <span className="simpleText">Selection</span>

                                        </div>



                                        <div className="column75">

                                            <div style={{
                                                paddingTop: '10px', display: 'flex', flexDirection: 'column',
                                                alignItems: 'center', justifyContent: 'space-between'
                                            }}>
                                                

                                                <FontsList style={{ display: 'flex' }} selectedFont={selectedFont} onFontChange={handleFontChange} />
                                                <p>Selected Font: <span className="simpleText"
                                                        style={{ fontFamily: selectedFont }}>{selectedFont}</span>
                                                </p>
                                            </div>

                                        </div>
                                    </li>


                                </div>



                                {/*<br></br>*/}
                                <div className="blueLine"></div>





                                <div>

                                    <li className="columns-container">
                                        <div className="textUploadAudio">

                                            <span style={{ fontSize: '2em' }}>2. </span>
                                            <i><b>Text & Frame </b></i>
                                            <span className="simpleText">colors</span>
                                        </div>

                                        <div className="column75" >

                                             <div className="column13">
                                                        <ColorPicker id="color1" name={colorValues.color1.name} Initcolor={colorValues.color1.color} Initopacity={colorValues.color1.opacity} onColorChange={handleColorChange} />
                                                    </div>
                                            <div className="column13">
                                                        <ColorPicker id="color2" name={colorValues.color2.name} Initcolor={colorValues.color2.color} Initopacity={colorValues.color2.opacity} onColorChange={handleColorChange} />
                                                    </div>

                                                    {postId === 3 && (
                                                <div className="column13">
                                                            <ColorPicker id="color3" name={colorValues.color3.name} Initcolor={colorValues.color3.color} Initopacity={colorValues.color3.opacity} onColorChange={handleColorChange} />
                                                        </div>
                                            )} 

                                        </div>
                                    </li>

                                </div>








                                <div className="blueLine"></div>






                                <div >

                                    <li className="columns-container">
                                        <div className="textUploadAudio">

                                            <span style={{ fontSize: '2em' }}>3. </span>
                                            <i><b>Align </b></i>
                                            <span className="simpleText">text</span>
                                        </div>

                                        <div className="column75">

                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className="simpleText" style={{ marginBottom: '5px' }}>
                                                    Left
                                                </p>
                                                <label className="container-radioButton">
                                                    <input type="radio"
                                                        checked={tracksAlignment === 'left'}
                                                        onChange={() => setTracksAlignment('left')}
                                                        name="radio" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>


                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className="simpleText" style={{ marginBottom: '5px' }}>
                                                    Center
                                                </p>
                                                <label className="container-radioButton">
                                                    <input type="radio"
                                                        checked={tracksAlignment === 'center'}
                                                        onChange={() => setTracksAlignment('center')}
                                                        name="radio" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className="simpleText" style={{ marginBottom: '5px' }}>
                                                    Right
                                                </p>
                                                <label className="container-radioButton">
                                                    <input type="radio"
                                                        checked={tracksAlignment === 'right'}
                                                        onChange={() => setTracksAlignment('right')}
                                                        name="radio" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>

                                        </div>
                                    </li>


                                </div>





                                <div className="blueLine"></div>


                                <div >

                                    <li className="columns-container">
                                        <div className="textUploadAudio">

                                            <span style={{ fontSize: '2em' }}>4. </span>
                                            <i><b>Hide </b></i>
                                            <span className="simpleText">stuff</span>

                                        </div>



                                        <div className="column75">

                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <p className="simpleText" style={{ marginBottom: '5px' }}>
                                                    Background Mask
                                                </p>
                                                <div style={{ flexGrow: 1 }}></div> 
                                                <label className="checkbox-wrapper" >
                                                    <input
                                                        type="checkbox"
                                                        id="hidebackgroundmask"
                                                        checked={hideBackgroundMask}
                                                        onChange={handleHideBackgroundMaskChange}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </label>
                                            </div>


                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                                <p className="simpleText" style={{ marginBottom: '5px' }}>
                                                    Cover Mask
                                                </p>
                                                <div style={{ flexGrow: 1 }}></div> 
                                                <label className="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        id="hidecovermask"
                                                        checked={hideCoverMask}
                                                        onChange={handleHideCoverMaskChange}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </label>
                                            </div>


                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className="simpleText" style={{ marginBottom: '5px' }}>
                                                    Track Number
                                                </p>
                                                <div style={{ flexGrow: 1 }}></div>

                                                <label className="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        id="numbercheck"
                                                        checked={numberCheck}
                                                        onChange={handleNumberCheckChange}
                                                        style={{ cursor: 'pointer' }}
                                                    />
                                                </label>
                                            </div>


                                            <div className="column15" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <p className={`simpleText ${postId !== 3 ? 'gray-text' : ''}`} style={{ marginBottom: '5px' }}>
                                                    Album Title
                                                </p>
                                                <div style={{ flexGrow: 1 }}></div> 
                                                <label className="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        id="albumcheck"
                                                        checked={albumCheck}
                                                        onChange={handleAlbumCheckChange}
                                                        style={{ cursor: postId === 3 ? 'pointer' : 'not-allowed' }}
                                                        disabled={postId !== 3}
                                                    />
                                                    {postId !== 3 && (
                                                        <span className="checkboxTooltip">Effect disabled for this template</span>
                                                    )}
                                                </label>
                                            </div>




                                        </div>
                                    </li>


                                </div>



                            </div>
                        </div>
                    )}


                   


                    {/*Audio Files*/}
                    {activeWindowButton === 'AudioFiles' && (
                        <div>
                            <h2 style={{ margin: 0, padding: 0 }}>Edit Audio Files</h2>
                            <br></br>

                            {/*<br></br>*/}
                            <div className="blueLine"></div>



                            <div>

                                {/* Hidden input for uploading audio */}
                                <input
                                    type="file"
                                    accept="audio/*"
                                    multiple
                                    ref={audioInputRef}
                                    style={{ display: 'none' }} // Hide the actual file input element
                                    onChange={handleInputSound}
                                />

                                {/* Hidden input for adding a new track */}
                                <input
                                    type="file"
                                    accept="audio/*"
                                    multiple
                                    ref={newTrackInputRef}
                                    style={{ display: 'none' }}
                                    onChange={handleNewTrackInput} // Logic for adding new track(s)
                                />



                                <div className="containerUploadAudio">

                                    <div className="textUploadAudio"
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
                                        <div>
                                            <span style={{ fontSize: '2em' }}>1. </span>
                                            <b><i>Upload</i></b>
                                            <span className="simpleText"> your Audio Files</span>
                                        </div>

                                        <div className="textUploadAudio"
                                            style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>

                                            <button onClick={handleAudioButtonClick}
                                                className="btn-with-icon"
                                            >
                                                <img src={UploadAudio} alt="icon" className="btn-icon" />
                                                Upload Audio
                                            </button>
                                            <span>{audioFiles.length > 0 ? ' ' + audioFiles.length + ' Files Selected' : ' No Files Chosen'}</span>
                                        </div>
                                    </div>










                                    <div className="textUploadAudio"
                                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>

                                        <div className="centered" style={{ margin: 0 }}>
                                            <div>
                                                <span style={{ fontSize: '2em' }}>2. </span>

                                                <b><i>Enter</i></b>
                                                <span className="simpleText"> your Album Title</span>
                                            </div>
                                            <div className="centered" style={{ margin: 0, paddingTop: '10px' }}>

                                                <textarea
                                                    className="textAudioArea"
                                                    type="text"
                                                    placeholder="Enter Album Title"
                                                    maxlength="40"
                                                    value={concatenatedVideoTitle}
                                                    onChange={handleConcatenatedVideoTitleChange}
                                                    style={{ marginBottom: 10 }}
                                                    disabled={isRendering}
                                                />

                                            </div>
                                        </div>
                                    </div>



                                </div>





                                {/*<br></br>*/}



                                <div className="blueLine"></div>



                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        {/* Audio List Title */}
                                        <h3>Audio List</h3>

                                        {/* Checkbox and Label */}
                                        <div className="form-control" style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="checkbox"
                                                id="numbercheck"
                                                checked={numberCheck}
                                                onChange={handleNumberCheckChange}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <label htmlFor="numbercheck" style={{ margin: 0, padding: '10px' }}>
                                                <b><i>Remove</i></b>
                                                <span className="simpleText"> Track Number</span>
                                            </label>
                                        </div>
                                    </div>






                                    {audioFiles.length > 0 && (

                                        <div style={{ paddingTop: '10px' }}  // Add padding to the top
                                        >



                                            <div style={{ position: 'relative' }}>
                                                {/* Header row (frozen) */}
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        fontWeight: 'bold',
                                                        padding: '0',
                                                        position: 'sticky',
                                                        top: 0,
                                                        zIndex: 1, // Ensures the header stays on top
                                                    }}
                                                >
                                                    <div className="column15" style={{ textAlign: 'center' }}>Move</div>
                                                    <div className="column25" style={{ textAlign: 'center' }}>Artist</div>
                                                    <div className="column25" style={{ textAlign: 'center' }}>Title</div>
                                                    <div className="column15" style={{ textAlign: 'center' }}>Delete</div>
                                                    <div className="column15" style={{ textAlign: 'center' }}>Preview</div>
                                                </div>

                                                {/* Scrollable Track List */}
                                                <div
                                                    style={{
                                                        height: '33vh', // Max height of the viewport height
                                                        minHeight: '200px', // Ensure it doesn't shrink below 200px
                                                        overflowY: 'auto', // Enable vertical scrolling
                                                        boxSizing: 'border-box', // Ensures padding doesn't affect the overall width
                                                    }}
                                                    className="styled-scrollbar"
                                                >


                                                    {audioFiles.map((soundfile, index) => (
                                                        <>
                                                            <li className="columns-container" key={index}>
                                                                {/* Move Up and Move Down Buttons */}
                                                                <div
                                                                    className="column15"
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '50px',
                                                                        margin: 'auto',
                                                                    }}
                                                                >
                                                                    {/* Move Up Button */}
                                                                    <button
                                                                        className="moveUpButton"
                                                                        onClick={() => handleMoveUp(index)}
                                                                        disabled={index === 0} // Disable if first track
                                                                        style={{
                                                                            cursor: index === 0 ? 'not-allowed' : 'pointer',
                                                                            border: 'none',             // Remove border
                                                                            backgroundColor: index === 0 ? '#505050' : '#007bff', // Grey background when disabled
                                                                            color: index === 0 ? '#6c757d' : '#ffffff',           // Grey arrow when disabled
                                                                            padding: '2px',            // Padding inside the button        
                                                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Normal shadow
                                                                            transition: 'box-shadow 0.1s ease',           // Smooth transition when clicked
                                                                            marginBottom: '1px',        // Space between up and down buttons

                                                                        }}
                                                                        onMouseDown={(e) => (e.target.style.boxShadow = 'inset 0px 4px 6px rgba(0, 0, 0, 0.2)')}  // Inner shadow on click
                                                                        onMouseUp={(e) => (e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)')}          // Reset shadow after click

                                                                    >
                                                                        ▲
                                                                    </button>

                                                                    {/* Move Down Button */}
                                                                    <button
                                                                        className="moveDownButton"
                                                                        onClick={() => handleMoveDown(index)}
                                                                        disabled={index === audioFiles.length - 1} // Disable if last track
                                                                        style={{
                                                                            cursor: index === audioFiles.length - 1 ? 'not-allowed' : 'pointer',
                                                                            border: 'none',             // Remove border
                                                                            backgroundColor: index === audioFiles.length - 1 ? '#505050' : '#007bff', // Grey background when disabled
                                                                            color: index === audioFiles.length - 1 ? '#6c757d' : '#ffffff',           // Grey arrow when disabled
                                                                            padding: '2px',            // Padding inside the button
                                                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Normal shadow
                                                                            transition: 'box-shadow 0.1s ease',           // Smooth transition when clicked
                                                                        }}
                                                                        onMouseDown={(e) => (e.target.style.boxShadow = 'inset 0px 4px 6px rgba(0, 0, 0, 0.2)')}  // Inner shadow on click
                                                                        onMouseUp={(e) => (e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)')}          // Reset shadow after click
                                                                    >
                                                                        ▼
                                                                    </button>
                                                                </div>

                                                                {/* Artist Field */}
                                                                <div
                                                                    className="column25"
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '50px',
                                                                        margin: 'auto',
                                                                    }}
                                                                >
                                                                    <textarea
                                                                        className="textAudioArea"
                                                                        type="text"
                                                                        value={soundfile.artist}
                                                                        onChange={(e) => handleAudioArtistChange(e, index)}
                                                                        maxLength={50}
                                                                        placeholder="Enter Artist Name"
                                                                    />
                                                                </div>

                                                                {/* Title Field */}
                                                                <div
                                                                    className="column25"
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '50px',
                                                                        margin: 'auto',
                                                                    }}
                                                                >
                                                                    <textarea
                                                                        className="textAudioArea"
                                                                        type="text"
                                                                        value={soundfile.title}
                                                                        onChange={(e) => handleAudioTitleChange(e, index)}
                                                                        maxLength={50}
                                                                        placeholder="Enter Song Title"
                                                                    />
                                                                </div>




                                                                {/* Delete Button */}
                                                                <div
                                                                    className="column15"
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '50px',
                                                                        margin: 'auto',
                                                                    }}
                                                                >


                                                                    <button
                                                                        className="deleteButton"
                                                                        onClick={() => handleDeleteTrack(index)}
                                                                        style={{
                                                                            cursor: 'pointer',
                                                                            background: 'transparent',
                                                                            border: 'none',
                                                                            padding: 0,
                                                                            fontSize: '24px',
                                                                            color: '#007bff',  // Change this to any color you want (e.g., black here)
                                                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',  // Normal shadow
                                                                            transition: 'box-shadow 0.1s ease',           // Smooth transition for shadow effect
                                                                        }}
                                                                        onMouseDown={(e) => (e.target.style.boxShadow = 'inset 0px 4px 6px rgba(0, 0, 0, 0.2)')}  // Inner shadow on click
                                                                        onMouseUp={(e) => (e.target.style.boxShadow = '0px 4px 6px rgba(0, 0, 0, 0.1)')}           // Reset shadow after click
                                                                    >
                                                                        <DeleteTrack ></DeleteTrack>
                                                                        {/*❌*/}
                                                                    </button>




                                                                </div>



                                                                {/* Preview Button */}
                                                                <div
                                                                    className="column15"
                                                                    style={{
                                                                        display: 'flex',
                                                                        flexDirection: 'column',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        height: '50px',
                                                                        margin: 'auto',
                                                                    }}
                                                                >

                                                                    <label className="container-radioButton">
                                                                        <input
                                                                            type="radio"
                                                                            checked={index === selectedAudioIndex}
                                                                            onChange={() => setSelectedAudioIndex(index)}
                                                                            name="radio"
                                                                        />
                                                                        <span className="checkmark"></span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            <div style={{ borderTop: '1px solid var(--theme-color)', marginTop: '10px' }}></div>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>




                                            <center style={{ paddingTop: '5px' }}  // Add padding to the top
                                            >
                                                {/* Button to trigger adding a new track */}
                                                <button
                                                    onClick={handleNewTrackButtonClick}
                                                    className="btn-with-icon"
                                                >
                                                    Add New Tracks
                                                </button>
                                            </center>


                                        </div>
                                    )}


                                </div>
                            </div>
                        </div>
                    )}







                    {/*RenderVideo*/}
                    {(activeWindowButton === 'RenderVideo' || activeWindowButton === 'Album Text')&& (



                        <div>
                            <div>


                                    <h2 >Render Videos</h2>
                                    <p className="simpleText">Currently, all videos are exported in HD .mp4</p>

                                <br></br>
                                <div className="blueLine"></div>





                                <br></br>



                                    <FFmpegProvider>

                                        <div>
                                        <AudioVisualizer37fFREE
                                                selectedFont={selectedFont}
                                                imgSourceBackground={imgSourceBackground}
                                                imgSourceCover={imgSourceCover}
                                                handleWindowButtonClick={handleWindowButtonClick}
                                                color1={colorValues.color1}
                                                color2={colorValues.color2}
                                                color3={colorValues.color3}

                                                minHueValue={minHueValue}
                                                maxHueValue={maxHueValue}
                                                selectedAudioFile={audioFiles[selectedAudioIndex]}
                                                postId={postId}

                                                audioFiles={audioFiles}
                                                albumTracksNames={albumTracksNames}
                                                longestName={longestName}
                                                concatenatedVideoTitle={concatenatedVideoTitle}
                                                tracksAlignment={tracksAlignment}
                                                albumCheck={albumCheck}
                                                hideBackgroundMask={hideBackgroundMask}
                                                hideCoverMask={hideCoverMask}

                                                onConcatenatedVideo={handleConcatenatedVideo}

                                                setOutputVideo={setOutputVideo}
                                                outputVideo={outputVideo}
                                                setVideos={setVideos}
                                                videos={videos}
                                                setVideoTitles={setVideoTitles}
                                                videoTitles={videoTitles}
                                                thereAreVideos={thereAreVideos}
                                                setThereAreVideos={setThereAreVideos}
                                                latestFrameUrl={latestFrameUrl}
                                                setLatestFrameUrl={setLatestFrameUrl}
                                            totalVideos={totalVideos}
                                            setTotalVideos={setTotalVideos}
                                            totalTimeSeconds={totalTimeSeconds}
                                            setTotalTimeSeconds={setTotalTimeSeconds}
                                            totalFileSizeMB={totalFileSizeMB}
                                            setTotalFileSizeMB={setTotalFileSizeMB}

                                            />
                                        </div>
                                    </FFmpegProvider>

                            </div>
                        </div>
                    )}






                    {/*Videos Library*/}
                    {activeWindowButton === 'VideosLibrary' && (








                        <div>
                            <div>

                                <h2 style={{ margin: 0, padding: 0 }}>Your Videos Library</h2>

                                <p className="simpleText">Scroll through your previously Rendered Albums</p>

                                <br></br>


                                <div className="blueLine"></div>
                                <br></br>
                                <br></br>

                                <center>
                                    <p className="simpleText"><i>This Feature is Coming Soon..</i></p>
                                    <p className="simpleText"><i>Stay Tuned!</i></p>
                                </center>


                            </div>
                        </div>



                    )}



                    



                </div>

                {/*Golden Small*/}
                <div className="columnGoldenSmall">

                    {/*Preview Scope*/}
                    {(activeWindowButton !== 'RenderVideo' && activeWindowButton !== 'Album Text' && activeWindowButton !== 'VideosLibrary')
                        && (
                 

                            <div>


                            <h2 style={{ margin: 0, padding: 0 }}>Preview Scope</h2>

                                <br />
                            <div className="blueLine"></div>
                            <br />

                                <center>

                                    <FFmpegProvider>

                                            <div style={{ paddingTop: '10px' }}>
                                                <AudioVisualizer34e
                                            selectedFont={selectedFont}
                                            imgSourceBackground={imgSourceBackground}
                                            imgSourceCover={imgSourceCover}
                                            handleWindowButtonClick={handleWindowButtonClick}
                                            color1={colorValues.color1}
                                            color2={colorValues.color2}
                                            color3={colorValues.color3}
                                            minHueValue={minHueValue}
                                            maxHueValue={maxHueValue}
                                            selectedAudioFile={audioFiles[selectedAudioIndex]}
                                            postId={postId}
                                            audioFiles={audioFiles}
                                            albumTracksNames={albumTracksNames}
                                            longestName={longestName}
                                            concatenatedVideoTitle={concatenatedVideoTitle}
                                            tracksAlignment={tracksAlignment}
                                            albumCheck={albumCheck}
                                            hideBackgroundMask={hideBackgroundMask}
                                            hideCoverMask={hideCoverMask}
                                                />
                                        </div>
                                    </FFmpegProvider>

                                </center>
                            </div>
                    )}






                    {/*RenderVideo - Generated Videos*/}

                    <>

                        {(activeWindowButton === 'RenderVideo' || activeWindowButton === 'Album Text' || activeWindowButton === 'VideosLibrary')
                            && (
                            <div className="button-container-ChooseImage">
                                <button
                                    className={getButtonClassName('RenderVideo')}
                                    onClick={() => handleWindowButtonClick('RenderVideo')}
                                >
                                    Rendered Videos
                                </button>

                                <button
                                    className={getButtonClassName('Album Text')}
                                    onClick={() => handleWindowButtonClick('Album Text')}
                                >
                                    Album Text
                                </button>
                            </div>
                        )}

                        {(activeWindowButton === 'RenderVideo' || activeWindowButton === 'VideosLibrary') && (

                            <div>
                                <h2>Generated Videos</h2>
                                <p className="simpleText" style={{ paddingBottom: '10px' }}>Videos might need a moment to load</p>

                                <div style={{
                                    display: 'flex', // Use flexbox to line items up in a row
                                    overflowX: 'auto', // Allow horizontal scrolling
                                    gap: '10px', // Space between videos
                                    maxHeight: '30vh', // Max height for the container
                                    boxSizing: 'border-box', // Adjust box sizing
                                    paddingBottom: '0px', // Adds padding at the bottom of the container
                                }} className="styled-scrollbar">

                                    {/* Your content for RenderVideo */}
                                    {videos.map((video, index) => {

                                        const title = videoTitles[index].endsWith('.mp4') ? videoTitles[index].slice(0, -4) : video.title;


                                    return (


                                        <div key={index} className="centered">

                                            <video
                                                ref={player => playerRefs.current[index] = player}
                                                src={video.url}
                                                controls
                                                playing={playingIndex === index}
                                                width="200px"
                                                height="auto"
                                                onPlay={() => handlePlay(index)}
                                                onPause={handlePause}
                                                controlsList="nodownload noplaybackrate"
                                                disablePictureInPicture
                                                preload="metadata"
                                                type="video/mp4"
                                           />



                                            <div className="video-footer">
                                                <p className="video-title">{title}</p>
                                                <div className="download-button-container">
                                                    <Button
                                                        className={"image-editor-navigation__button"}
                                                        onClick={() => handleDownload(video.url, video.title)}
                                                    >
                                                        <DownloadIcon />
                                                    </Button>
                                                </div>
                                            </div>


                                        </div>
                                    )
                                    })}
                                </div>

                                {concatenatedVideoUrl && (

                                    <div className="centered" style={{ marginTop: '10px', paddingBottom: '5px', }}>
                                        <video
                                            width="200"
                                            height="auto"
                                            src={concatenatedVideoUrl}
                                            controls
                                            onPlay={() => handlePlay(videos.length)} // Use a unique index for the concatenated video
                                            onPause={handlePause}
                                            ref={video => playerRefs.current[videos.length] = video} // Add ref for the concatenated video
                                            controlsList="nodownload noplaybackrate"
                                            disablePictureInPicture
                                            preload="metadata"
                                            type="video/mp4"
                                        />

                                        <div className="video-footer">
                                            {/*<span className="video-title">Album Title:</span> {concatenatedVideoTitle} - Video Album*/}

                                            <p className="video-title">{concatenatedVideoTitle}</p>
                                            <div className="download-button-container">
                                                <Button
                                                    className={"image-editor-navigation__button"}
                                                    onClick={handleConcatenatedVideoDownload}
                                                >
                                                    <DownloadIcon />
                                                </Button>
                                            </div>
                                        </div>




                                        <div className="centered" style={{ marginTop: 0, paddingBottom: '5px', }}>
                                            <div className="zipAndDownload">
                                                <button onClick={zipAndDownload} className="btn-with-icon">
                                                    <img src={DownloadZip} alt="icon" className="btn-icon" />

                                                    Zip & Download All
                                                </button>
                                            </div>
                                        </div>

                                    </div>




                                )}









                            </div>
                        )}

                        {activeWindowButton === 'Album Text' && (
                            <div>
                                {/* Your content for something else */}
                                

                                <h2>Audio Durations and Titles</h2>
                                <p className="simpleText">Copy the following text and paste it in the description section of Youtube,</p>
                                <p className="simpleText"> to add song timestamps, if you upload the 'Video Album'!</p>

                                <br />
                                <div className="blueLine"></div>
                                <br />

                                <h3 className="simpleText">{concatenatedVideoTitle}</h3>
                                <br />

                                <div style={{
                                    height: '33vh', // Max height of the viewport height
                                    minHeight: '200px', // Ensure it doesn't shrink below 200px
                                    overflowY: 'auto', // Show vertical scrollbar when needed
                                    boxSizing: 'border-box', // Ensures padding doesn't affect the overall width
                                }} className="styled-scrollbar">

                                {videos.map((video, index) => {
                                    const title = video.title.endsWith('.mp4') ? video.title.slice(0, -4) : video.title;
                                    return (

                                        <div  key={index} style={{ display: 'flex', alignItems: 'center', textAlign: 'left' }}>
                                            <span style={{ margin: 1 }}>
                                                <b>{formatDuration(cumulativeDurations[index])}</b>
                                                {/*<span className="simpleText"> - {title}</span>*/}
                                                <span className="simpleText"> {title}</span>
                                            </span>
                                        </div>
                                    );
                                })}
                                </div>
                                <br />




                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                    <CopyToClipboard text={generateCopyText()} onCopy={handleCopy}>
                                        <button className="btn-with-icon">
                                            <img src={CopyToClipboardIcon} alt="icon" className="btn-icon" />
                                            Copy All to Clipboard
                                        </button>
                                    </CopyToClipboard>
                                    <div
                                        className={`popup ${copied ? 'show' : ''}`}
                                        style={{
                                            position: 'absolute',
                                            top: '50%',
                                            left: '100%',
                                            transform: 'translateY(-50%)',
                                           // background: '#333',
                                            color: '#fff',
                                          //  padding: '5px 10px',
                                            borderRadius: '3px',
                                           // marginLeft: '10px',
                                            whiteSpace: 'nowrap',
                                            opacity: copied ? 1 : 0,
                                            transition: 'opacity 0.5s ease-in-out',
                                        }}
                                    >
                                        Copied
                                    </div>
                                </div>


                            </div>
                        )}



                    </>





                    </div>
                </div>
            </div>          


    );
};

export default MainFunction_Tier_0;
