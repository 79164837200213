import { Link } from "react-router-dom";

const Card = ({post}) => {
    return (
        <div className="card">
            <Link className="link" to={`/post/${post.id}`}>
                <h3><span className="title">{post.title}</span></h3>
            <img src={post.img} alt="" className="img" />
            <p className="desc">{post.desc}</p>
            <button className="cardButton">Start Editing</button>
            </Link>
        </div>
    );
};

export default Card;