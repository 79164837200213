import { posts } from "../templatesData";
import  Card  from "../components/Card";
import '../App.css';


const Home = () => {
    return (
        <div className="home">


            <div className="desc" style={{
                textAlign: 'center', paddingBottom: '10px', paddingTop: '10px', width: '100%'
            }}>
                <div >
                    <h3 style={{
                        color: '#007bff', paddingBottom: '5px'
                    }}>Transform Your Music Album into Video Effortlessly!</h3>
                    <i>Album Animator is your go-to platform for transforming entire music albums
                        into stunning videos with just a few clicks.</i>
                    <br />
                    <i>Whether you’re an artist, producer, or enthusiast, Album Animator automates the video creation process,
                        saving you time and effort.</i>
                </div>
            </div>


            {posts.map(post => (
                <Card key={post.id} post={post} />
            ))}

            

            <div className="desc" style={{
                textAlign: 'center', paddingTop: '10vh', width: '100%'
            }}>
                <div >
                    <h3 style={{
                        color: '#007bff', paddingBottom: '5px'
                    }}>Features</h3>
                    Easy Upload: Upload your entire music album in one go.
                    <br />
                    Automatic Rendering: Our advanced algorithms render your music into visually captivating videos.
                    <br />
                    Customization: Choose from a variety of templates and visual styles to match your album’s vibe.
                    <br />
                    High Quality: Produce high-definition videos suitable for sharing on all major platforms.
                </div>
            </div>



        </div>
    )

}

export default Home;